import React from "react"
import PropTypes from "prop-types"
import { Button, Modal } from "antd"

const AboutModal = ({ isOpen, onCancel }) => {
	return (
		<Modal
			title={"About"}
			open={isOpen}
			onCancel={onCancel}
			footer={[
				<Button key="close" onClick={onCancel}>
					Close
				</Button>,
			]}
		>
			<div>
				<span style={{ fontSize: 10, marginRight: 10, color: "rgb(0 0 0 / 51%)" }}>{"v 1.5.4 [05/14/2024]"}</span>
			</div>
		</Modal>
	)
}

AboutModal.propTypes = {
	isOpen: PropTypes.bool,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
}

export default AboutModal
